import { Injectable } from '@angular/core';

import { URL } from '@app-shared/constants/service-urls';
import { ApiService } from '@app-shared/services/api.service';
import { APIRequest } from '@app-shared/services/req-res.types';
import { CREATE, GLOBAL, VIEW } from '@app/shared/constants/application-constants';

@Injectable({
  providedIn: 'root'
})
export class DialogService {
  
  constructor(
    private api: ApiService){}

  get jwt(): string | null{
    return localStorage.getItem('JwtToken');
  }

  getAdminById(adminID: number){
    const postData: APIRequest = {
       
      requestBody: {
        adminID
      }
    };

    const headers = {
      "x-operation" : VIEW,
      "x-referer": GLOBAL
    };
    
     
    return this.api.post(URL.ADMIN.GET_ADMIN, postData, headers );
  }

  createOrEditAdmin(data: any) {
    const postData: APIRequest = {
      requestBody: {
        ...data,
      },
    };

    const headers = {
      "x-operation" : CREATE,
      "x-referer": GLOBAL
    };

    return this.api.post(URL.ADMIN.CREATE_UPDATE_ADMIN, postData, headers);
  }

  getMOUs(currentOrgID: number){
    const postData: APIRequest = {
      requestBody: {
        orgID: currentOrgID
      }
    };

    const headers = {
      "x-operation" : VIEW,
      "x-referer": GLOBAL
    };

    return this.api.post(URL.ADMIN.GET_ALL_MOUS, postData, headers);
  }
}